import React from 'react'
import FooterNav from './FooterNav'
import { FaCaretRight, FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { HiShare } from 'react-icons/hi'
import "../../../assets/styles/Footer/footerAbout.css";


const BlogLandingPage = () => {
    return (
        <>
            <div className='bg-white' style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}>
                <FooterNav from={"Blog"}/>
                <div className="container pb-5">
                    <div className="mt-4 pb-3">
                        <div className="">
                            <div className="">
                                <div className="card" style={{ border: "none" }}>
                                    <div className="">
                                        <img className="card-img-top" style={{ maxWidth: "1400px" }} src="Images/Blog2.jpeg" alt="Card imagess cap" />
                                        <a href="#!">
                                            <div className="mask rgba-white-slight"></div>
                                        </a>
                                    </div>
                                    <div className="text-center" style={{ height: "175px", margin: "-40px 25px 0px 25px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#fff", zIndex: 10, paddingTop: "20px", borderRadius: "4px" }}>
                                        <h2 className='text-dark'><strong id="blogTitle">How To Make 2020 Your Best Year Yet</strong></h2>
                                        <p>Written by Lisa Rowlett Leslie, 7/24/2020</p>

                                        <div className="social-counters ">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.linkedin.com/shareArticle/?mini=true&url=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaLinkedin size={24} style={{ cursor: "pointer" }} color='#0082ca' className='mx-2' href="www.linkedin.com/in/tg308" />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ftweet%3Ftext%3DHow%2520To%2520Make%25202020%2520Your%2520Best%2520Year%2520Yet%2520http%253A%252F%252Flocalhost%253A8081%252FblogLandingPage.html"
                                            >
                                                <FaTwitter size={24} style={{ cursor: "pointer" }} color='#55acee' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A8081%2FblogLandingPage.html"
                                            >
                                            <FaFacebook size={24} style={{ cursor: "pointer" }} color='#3b5998' className='mx-3' />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/ibougeinc"
                                            >
                                            <FaInstagram size={24} style={{ cursor: "pointer" }} color='#DD2A7B' className='mx-3' />
                                            </a>
                                        </div>


                                    </div>
                                </div>
                                <div className="excerpt mt-5">
                                    <p>So many people are talking about how 2020 has been the worst year ever of their entire life. Ok, I get it. We are in the middle of a global pandemic with no known cure to Covid and the economy is suffering. People are suffering and the mood is grim.
                                    </p>

                                    <p>But what a lot of people don't seem to realize is that you can make your own happiness even in extremely trying and difficult situations. After all, happiness is a choice. </p>


                                    <p>Maybe you got laid off. Maybe a loved one has been sick. Maybe some of your favorite restaurants and stores are going out of business. Maybe you're feeling lonely and can't see your friends right now. Maybe you're scared about the future and the direction of our country. All of this may be true. But we can still create positive routines for ourselves and create our own sense of joy amidst the chaos and turmoil.
                                    </p>

                                    <p>What if...2020 was your best year yet? Think about the possibilities if you were to invest all of your free quarantine time into improving yourself and finishing off the year better than you've ever been before. You know how all the young college kids these days are taking a gap year off before they go to university? What if this was your gap year? An entire year to invest in yourself, explore, experiment, take on new hobbies, learn new things, and reinvent yourself? </p>

                                    <p>I'll be honest with you. I had been living in denial before 2020. My life seemed perfect from the outside. I always have nice pictures to post on social media. My husband and I were going on nice trips, but we were having trouble figuring out our new dynamic as a married couple. I wasn't taking very good care of myself and had no routines in place to have productive days let alone time set aside for self care. I was going to bed extremely late and my dogs were getting fed dinner at 1AM. I was exhausted and just hanging on by a thread. I just couldn't seem to do it all or keep up. And then the pandemic happened.
                                    </p>

                                    <p>All of a sudden. I was stuck at home with nowhere to go. Faced with all of my problems like a galloping wild horse speeding towards a cliff and looking at the options ahead with horror and dismay. But the great news is, you don't have to jump off that cliff. You can stop. Freeze. Think and evaluate your options. There are endless opportunities of what you could do with all this free time and self-destructing is just one option. But there are plenty of good ones.</p>

                                    <p>Maybe you have thought about going back to school to advance your career or take a course. Maybe this would be a great time to work on that startup idea you've had for a while. Maybe you've got a ton of books you've been meaning to read. Maybe you've wanted to start taking gardening more seriously and plant some raised beds. Maybe you want to lose weight.  Maybe you've wanted to spend more time with family. What is it that you've wanted to do but never seem to find the time? Well... now is a great time to try it.
                                    </p>

                                    <p>In the past four months I have decided to face my battles head on and I'll tell you, I am having a great year so far. I've been focusing on the little things that were overlooked until this point. After doing some research I finally found a great moisturizer that is improving my natural beauty routines and skin care and an investment in feeling confident and lovely about myself. I am working on understanding and improving my diet, sleep, stress, water intake and overall health to come out the end of this era feeling my strongest and best. I helped my husband for 4 months prepare and ace his interviews with Google and get a new job offer that will dramatically impact our livelihood. I have double downed on my startup and have had less distractions and made significant progress. I quit drinking alcohol for the most part besides maybe 1-2 drinks a week because I realized that's the threshold I need in order to feel my best. I have taken up open water swimming since my local pool is closed. I have spent time looking into our finances and working with a professional to develop a more effective budget and savings plan for our household. I applied to an intermediate coding program at UW and was accepted, which will help continue to advance my career as a software engineer and entrepreneur. I'm reading and learning about all sorts of things. Instead of overindulging at restaurants, we are cooking more, staying close to home and going on more walks in the evenings. </p>

                                    <p>So what I'm trying to say is...this could be precious time and you should use it wisely. The world will eventually come out of this situation stronger, more cohesive and aligned than ever before. Will you be ready to take life by storm post-Covid? I know deep in my heart that we'll all be on vacation again someday with our feet in the sand, looking back on this period with reflection. And we can either be feeling terrible about ourselves and state-of-affairs, or celebrating our milestones and accomplishments coming out on top from this gritty, challenging, character-building opportunity in life to take a breath and say...what do I want to get out of this? I encourage you to take the higher path and take a leap of faith in yourself. </p>

                                    <p>Imagine what the world could be like if we all came out of this a little better off? Billions of people who better understand themselves, prioritize well-being, mental health, self-improvement, have grace, are empathetic, present and happier individuals. </p>

                                    <div className="mt-4 d-flex justify-content-end flex-wrap-reverse">
                                        <span className="badge pink">Self-Improvement</span>
                                        <span className="badge bg-primary mx-1">Self-Care</span>
                                        <span className="badge bg-secondary mx-1">Inspiration</span>
                                        <span className="badge bg-info mx-1">Coronavirus</span>
                                        <span className="badge pink mx-1">Health</span>
                                        <span className="badge bg-primary mx-1">Fitness</span>
                                        <span className="badge bg-secondary mx-1">Lifestyle</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='container '>
                    <hr className="mb-5 mt-2" />
                    <div className="pb-3">
                        <h3 className="text-center my-5 text-center h3 pt-3">Recent posts</h3>
                        <div className="row">
                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card">
                                    <div className="view overlay">
                                        <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%28147%29.jpg" className="card-img-top" alt="" />
                                        
                                            <div className="mask"></div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h4 className="card-title">Title of Article</h4>
                                            <div className="activator mr-3"> <HiShare color='black' size={23} /> </div>
                                        </div>
                                        <hr />
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                            card's content.</p>
                                        <h5 className='h5big' style={{ color: "black", cursor: "pointer" }}>Read more <FaCaretRight /> </h5>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="card">
                                    <div className="view overlay">
                                        <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%2850%29.jpg" className="card-img-top" alt="" />
                                    
                                            <div className="mask"></div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h4 className="card-title">Card title</h4>
                                            <div className="activator mr-3"> <HiShare color='black' size={23} /> </div>
                                        </div>
                                        <hr />
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                            card's content.</p>

                                        <h5 className='h5big' style={{ color: "black", cursor: "pointer" }}>Read more <FaCaretRight /> </h5>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="card">
                                    <div className="view overlay">
                                        <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%2897%29.jpg" className="card-img-top" alt="" />
                                        
                                            <div className="mask"></div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h4 className="card-title">Card title</h4>
                                            <div className="activator mr-3"> <HiShare color='black' size={23} /> </div>
                                        </div>
                                        <hr />
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                                            card's content.</p>

                                        <h5 className='h5big' style={{ color: "black", cursor: "pointer" }}>Read more <FaCaretRight /> </h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', marginBottom: "10px" }}>
                        <div className="p-5 text-center text-md-left author-box" >

                            <h4 className="h3-responsive text-center font-weight-bold dark-grey-text">About Author</h4>

                            <hr />

                            <div className="row">
                                <div className="col-12 col-md-2 col-xl-3 my-auto">
                                    <img id="authorPhoto" src="img/BlogAuthorProfilePhoto.png" alt='BlogAuthor' style={{ borderRadius: "50%", height: "270px", width: "250px" }} />
                                </div>

                                <div className="col-12 col-md-10 col-xl-9 text-lg-start text-md-start text-sm-center">
                                    <h5 className="font-weight-bold text-dark mb-3 h5big">
                                        <strong>Lisa Rowlett Leslie</strong>
                                    </h5>
                                    <div className="personal-sm pb-3">
                                        <FaLinkedin size={24} color='#0082ca' className='mx-2' />
                                        <FaTwitter size={24} color='#55acee' className='mx-3' />
                                        <FaFacebook size={24} color='#3b5998' className='mx-3' />
                                        <FaInstagram size={24} color='#DD2A7B' className='mx-3' />
                                    </div>
                                    <p id="authorBio">Lisa is an information architect and entrepreneur who loves to develop websites and travel the world.</p>
                                    <p id="authorBio">Lisa holds a double major and a graduate certificate from the University of Washington and has lived in Paris, Dubai, NYC, SF, Seattle and Portland, Oregon.
                                    </p>
                                    <p id="authorBio">She is the founder and CEO of iBouge.com, the world's first geo-networking platform as well as the main contributor for the iBouge blog.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='pt-3'>
                    <p>iBouge ® • Copyright © 2025 iBouge Inc. • All Rights Reserved.</p>
                    <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
                </footer>
            </div>
        </>
    )
}

export default BlogLandingPage
