import React from "react";
import { Card } from "reactstrap";
import "../../../assets/styles/Footer/mission.css";
import "../../../assets/styles/Footer/contactUs.css";
import "../../../assets/styles/Footer/footerAbout.css";
import FooterNav from "./FooterNav";
import { Helmet } from "react-helmet";
import FootNav from "../../User_Profile/Footer/Footer-nav/FootNav";

const Mission = () => {
  return (
    <div style={{ background: "white" }}> 
      <Helmet>
        <meta property="og:url" content="mission.html" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="How To Make 2020 Your Best Year Yet" />
        <meta property="og:description" content="How to make the most of your time during the pandemic" />
        <meta property="og:image" content=" images/IMG_6188.jpeg" />
      </Helmet>
      <FooterNav from={"Mission"}/>
      <div className="MainContact mb-3">
        <div className="Contactheading">
          <p>iBouge's Mission</p>
        </div>
        <div className="Mission-card-main">
          <Card className="Mission-card-customize">
            <div className="Mission-border_top"></div>
            <div>
              <img
                src="/img/Rectangle139.png"
                alt="Population Globe"
                className="Mission-img"
              />
            </div>
            <div className="mt-3">
              <span className="Mission-LoginHeading">Expanding your world</span>
              <p className="Mission-p">
                iBouge's patented platform allows users to find businesses,
                people and information for any country in the world. Our world
                map designs create a universally open platform for searching for
                businesses, connecting to new people and engaging with
                communities that matter to you.
              </p>
              <p className="Mission-p">
                Our mission is to be the most open information portal on the web
                for people, businesses and communities.
              </p>
              <p className="Mission-p">
                With iBouge, you can find and connect with any individual in the
                world or find any business globally without typing in a keyword
                search. Simply click on our map and zoom in to find businesses
                in places you care about or people you'd like to meet. We will
                soon be opening shops with products for sale on our platform so
                you can shop by location.
              </p>
              <p className="Mission-p">
                The world is more globally connected than ever before and iBouge
                is tailored to making your information centralized and open so
                you have no limits on where to buy from, who you can connect
                with or which communities you can engage with.
              </p>
              <p className="Mission-p">
                With iBouge, the world is at your fingertips.
              </p>
            </div>
            {/* <LastFooter /> */}
            <footer>
              <p>iBouge ® • Copyright © 2025 iBouge Inc. • All Rights Reserved.</p>
              <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
            </footer>
          </Card>
        </div>
      </div>
      <FootNav from={"Mission"}/>
    </div>
  );
};

export default Mission;