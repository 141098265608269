import React, { useState, useEffect } from 'react'
import { OtpInput } from 'reactjs-otp-input';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from 'axios';
import { Server2 } from '../redux/actions/auth/auth.actions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const OtpModel = ({ setOTPModal, otpModal}) => {
  const userData = useSelector((state) => state?.auth?.user?.user?state?.auth?.user?.user:state?.auth?.forgotPassword?.user);
  const forget = useSelector((state) => state?.auth.forgotPassword)
  const [otp, setOtp] = useState('');
  const [verifyRes, setVerifyRes] = useState(false);
  const [timer, setTimer] = useState(60);
  const handleOTPChange = (otp) => setOtp(otp);
  const navigate = useNavigate()

  const handleVerification = () => {
    var config = {
      method: "post",
      url: Server2 + "/auth/activate-account",
      headers: { "Content-Type": "application/json" },
      data: {email: userData.email, token: otp},
    };

    axios(config)
      .then(function (response) {
        console.log("verified?", response.data.state);
        setVerifyRes(response.data)
        if (response.data.state === 'success'){
            setOTPModal(false)
            if(forget?.forgetScreen === true){
              navigate("/new_password")
            }else{
              navigate("/login")
            }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOTPRefresh = () => {
    var config = {
      method: "post",
      url: Server2 + "/auth/regen-otp",
      headers: { "Content-Type": "application/json" },
      data: {email: userData.email, token: otp},
    };

    axios(config)
      .then(function (response) {
        console.log("generated?", response.data.state);
        setVerifyRes(response.data)
        if (response.data.state === 'success'){
          setTimer(60);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);
  return (
    <>
    <Modal isOpen={otpModal} toggle={() => setOTPModal(!otpModal)} centered trapFocus backdrop={'static'} className="">
      <ModalHeader toggle={() => setOTPModal(!otpModal)}>Email Verification</ModalHeader>
      <ModalBody className="d-flex justify-content-center flex-column align-items-center gap-2 pt-4 pb-5" >
        <div className="text-center mb-4">
          <p>Please enter OTP sent to email below:</p>
          {userData?.email}
          { verifyRes.state === 'success' ? <>
            <p className="text-success pt-4">{verifyRes.user.message}</p>
          </> : verifyRes.state === 'error' ? <>
            <p className="text-danger pt-4">{verifyRes.err.message}</p>
          </> : null
          }
        </div>
        <OtpInput
          value={otp}
          onChange={handleOTPChange}
          numInputs={6}
          inputStyle={{width: 50, height: 50, backgroundColor: "#CFD5DD", border: 0, borderRadius: 6, marginRight: 4, marginLeft: 4}}
          isInputNum={true}
        />
        { timer === 0 ?
          <div className="text-end w-100 me-5 pe-5">
            <p style={{color: "#6047A0", cursor: 'pointer'}} onClick={() => handleOTPRefresh()}>Resend OTP</p>
          </div>
        :
          <div className="text-center w-100">
            <p style={{color: "#000"}}>OTP expires in {timer} seconds</p>
          </div>
        }
        <Button className="submit-btn ib-btn w-100" onClick={() => handleVerification()}>Verify</Button>
      </ModalBody>
    </Modal>
    </>
  )
}

export default OtpModel