import React, { useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import { BsPaperclip } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import InputEmoji from "react-input-emoji";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Server2, Socket } from "../../../redux/actions/auth/auth.actions";
import moment from "moment/moment";
import ModalImage from "react-modal-image";
import { Loader } from "../../../components/Loader";
import LinkPreview from "../../../components/LinkPreview";
import Linkify from "linkify-react";
import { thumbnailGen } from "../../../redux/actions/auth/auth.actions";

export const ChatBox = ({ setChatBox, Data, user, Room }) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [ShowMessage, setShowMessage] = useState([]);
  const [TypingIcon, setTypingIcon] = useState(false);
  const [typingNames, setTypingNames] = useState([]);
  // const [msg, setMsg] = useState(false);
  const [files, setFiles] = useState(null);
  const [image, setImage] = useState("");
  const [TypeFile, setTypeFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const inputFile = useRef(null);
  const [Loading, setLoading] = useState(false);
  const [loaderVideo, setLoadervideo] = useState(false);
  const [IsOnline, setIsOnline] = useState([]);
  const [Is_Online, setIs_Online] = useState(Data?.[0]?.item?.is_online);
  const [readByNameOpen, setReadByNameOpen] = useState({ name: "", index: "" });
  const [isReadByOpen, setIsReadByOpen] = useState({ state: 0, index: 0 });
  const [thumbnail, setThumbnail] = useState("");
  const [showChatUsers, setShowChatUsers] = useState(false);

  const downRef = useRef(null);
  // Group States
  // const [GetGroupRoom, setGetGroupRoom] = useState(Room ? Room : "");
  const GetGroupRoom = Room ? Room : "";
  const CreateRoom = (me, friend) => {
    return me < friend ? friend + "___" + me : me + "___" + friend;
  };

  const room_id =
    Data.length === 1
      ? CreateRoom(userData?._id, Data[0]?._id).toString()
      : Room;

  useEffect(() => {
    setLoading(true);
    setIs_Online(Data?.[0]?.item?.is_online);
    if (Data.length === 1) {
      // setMsg(false);
      const room = {
        id: userData?._id,
        room: room_id,
        isGroupChat: false,
      };
      Socket.emit("join-room", room);

      Socket.on("connect_error", (err) => {
        console.log("connect_error", err);
      });
      const dataonline = {
        to: Data[0]?._id,
      };
      Socket.emit("online", dataonline);
      GetChat(room_id);
      return () => {
        Socket.off("connect_error");
      };
    } else {
      const room = {
        id: userData?._id,
        room: GetGroupRoom,
        isGroupChat: true,
      };
      Socket.emit("chat-seen", room);

      Socket.emit("join-room", room);

      GetGroupChat(GetGroupRoom);
    }
  }, [Data, Socket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Data.length === 1) {
      // setMsg(true);
      const handler = (chat) => {
        if (chat.from === Data[0]._id) {
          setTypingIcon(false);
          setShowMessage(chat.messages);
          console.log(chat.messages[0].from, "chat.messages[0].from");
          if (
            chat.messages[0].from !== userData?._id &&
            chat.messages[0].read_at === null
          ) {
            downRef?.current?.scrollIntoView({ behavior: "smooth" });
            Socket.emit("read", {
              room: room_id,
              to: Data[0]?._id,
              last: true,
            });
          }
        }
      };
      Socket.on("read", (data) => {
        GetChat(room_id);
      });
      Socket.on("isonline", (data) => {
        setIs_Online(data.status);
      });

      Socket.on("message", handler);
      Socket.on("typing", (data) => {
        if (data.to === userData?._id && data.room === room_id) {
          if (data.statustyping === false) {
            setTypingIcon(false);
          } else if (data.statustyping === true) {
            setTypingIcon(true);
          }
        }
      });
      return () => {
        Socket.off("message", handler);
        Socket.off("typing");
        Socket.off("read");
      };
    } else {
      Socket.on("typing", (data) => {
        if (data.statustyping === false) {
          setTypingNames((current) =>{
            return current.filter((item) => item.id !== data.from)
          })
        } else if (data.statustyping === true) {
          setTypingNames((current) =>{
            const isUser = current?.some(user => user.id === data.from)
            if(!isUser){
             return [...current, {id:data.from , name:data.fromName}]
            }
            return current
          })
        }
      });

      Socket.on("groupMessage", (data) => {
        const room = {
          id: userData?._id,
          room: GetGroupRoom,
          isGroupChat: true,
        };
        Socket.emit("chat-seen", room);
        // setShowMessage(data);
      });
      Socket.on("new-chatSeen", (data) => {
        GetGroupChat(GetGroupRoom);
      });
      return () => {
        Socket.off("groupMessage");
      };
    }
  }, [Socket, Data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Data.length === 1) {
      const prev = message;
      if (message.length > 0) {
        const data = {
          from: userData?._id,
          to: Data[0]?._id,
          room: room_id,
          statustyping: true,
        };
        Socket.emit("typing", data);
        const timer = setTimeout(() => {
          if (message === prev) {
            const data = {
              from: userData?._id,
              to: Data[0]?._id,
              room: room_id,
              statustyping: false,
            };
            Socket.emit("typing", data);
          }
        }, 500);
        return () => clearTimeout(timer);
      } else {
        const data = {
          from: userData?._id,
          to: Data[0]?._id,
          room: room_id,
          statustyping: false,
        };
        Socket.emit("typing", data);
      }
    } else {
      const prev = message;
      if (message.length > 0) {
        const data = {
          fromName: userData?.fname + " " + userData?.lname,
          from: userData?._id,
          to: Data,
          room: Room,
          statustyping: true,
        };
        // console.log('typping');
        Socket.emit("Group_typing", data);
        const timer = setTimeout(() => {
          if (message === prev) {
            const data = {
              fromName: userData?.fname + " " + userData?.lname,
              from: userData?._id,
              to: Data,
              room: Room,
              statustyping: false,
            };
            Socket.emit("Group_typing", data);
          }
        }, 500);

        return () => clearTimeout(timer);
      } else {
        const data = {
          fromName: userData?.fname + " " + userData?.lname,
          from: userData?._id,
          to: Data,
          room: Room,
          statustyping: false,
        };
        Socket.emit("Group_typing", data);
      }
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps
  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
    if (TypeFile === "video" && image) {
      thumbnailGen(files).then((thumb) => {
        setThumbnail(thumb);
      });
    }
  }, [videoSrc, image]);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleImageChange = (e) => {
    if (e.target.files[0].size > 52428800) {
      alert("File size is too big");
      return;
    }
    inputFile.current.click();
    setFiles(e.target.files[0]);
    console.log(e.target.files[0], "e.target.files[0]");
    const type = e.target.files[0].type.split("/");
    setTypeFile(type[0]);

    if (e.target.files && e.target.files[0]) {
      if (TypeFile === "video") {
        setImage("");
        const file = e.target.files[0];
        if (!file) return;
        setImage(URL.createObjectURL(file));
        setIsUpload(true);
      } else {
        setImage("");
        let reader = new FileReader();
        reader.onload = function (e) {
          setImage(e.target.result);
          setIsUpload(true);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };
  const GetChat = (roomid, responsedata) => {
    var config = {
      method: "get",
      url: Server2 + `/chats/history?room=${roomid}&limit=20&offset=0`,
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        for (let j = 0; j < Data?.length; j++) {
          for (let i = 0; i < response.data.messages.length; i++) {
            if (response.data.messages[i].from === Data[j]._id) {
              response.data.messages[i].profile = Data[j].item.profile_pic;
            }
          }
        }
        setShowMessage(response.data.messages);
        if (response.data.messages.length > 0) {
          if (
            response.data.messages[0].from !== userData?._id &&
            response.data.messages[0]?.read_at === null
          ) {
            Socket.emit("read", {
              room: room_id,
              to: Data[0]?._id,
              last: false,
            });
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };
  const GetGroupChat = (roomid) => {
    var config = {
      method: "get",
      url: Server2 + `/chats/history?room=${roomid}&limit=20&offset=0`,
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        setShowMessage([]);
        for (let j = 0; j < Data?.length; j++) {
          for (let i = 0; i < response.data.messages.length; i++) {
            if (response.data.messages[i].from === Data[j]._id) {
              response.data.messages[i].profile = Data[j].item.profile_pic;
            }
          }
        }
        setShowMessage(response.data.messages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const Add = () => {
    if (files !== null || message !== "") {
      if (TypeFile === "video") {
        setIsUpload(false);
        setLoadervideo(true);
      }
      let data = new FormData();
      data.append("albumName", "all");
      data.append("thumbnail", thumbnail ? thumbnail : null);
      data.append("file", files ? files : null);
      data.append("from", userData?._id);
      data.append("message", message);
      data.append("room", room_id);
      data.append("to", Data[0]?._id);
      data.append("message_type", TypeFile ? TypeFile : "text");
      data.append(
        "profile",
        userData?.profile_pic ? userData?.profile_pic : ""
      );
      var config = {
        method: "POST",
        data: data,
        url: Server2 + "/chats/new-message",
      };
      axios(config)
        .then(function (response) {
          setShowMessage(response.data.messages);
          var msg = {
            from: userData._id,
            messages: response.data.messages,
            room: room_id,
            to: Data[0]?._id,
            message: response.data.messages[0].message,
            fileName: response.data.messages[0].fileName === "" ? "" : "image",
            username: userData.fname + " " + userData.lname,
          };
          Socket.emit("message", msg);
          setLoadervideo(false);
          setMessage("");
          setIsUpload(false);
          setFiles(null);
          setTypeFile("");
        })
        .catch(function (error) {
          setLoadervideo(false);
          console.log(error);
        });
    } else {
      alert("Enter Msg");
    }
  };

  const AddGroup = () => {
    if (files !== null || message !== "") {
      if (TypeFile === "video") {
        setIsUpload(false);
        setLoadervideo(true);
      }
      let data = new FormData();
      data.append("thumbnail", thumbnail ? thumbnail : null);
      data.append("albumName", "all");
      data.append("file", files ? files : null);
      data.append("from", userData?._id);
      data.append("message", message);
      data.append("room", GetGroupRoom);
      data.append("to", Data[0]?._id);
      data.append("message_type", TypeFile ? TypeFile : "text");
      data.append(
        "profile",
        userData?.profile_pic ? userData?.profile_pic : ""
      );
      console.log(GetGroupRoom, "dataaaa");
      var config = {
        method: "POST",
        data: data,
        url: Server2 + "/chats/new-message",
      };
      axios(config)
        .then(function (response) {
          setShowMessage(response.data.messages);
          setLoadervideo(false);
          setMessage("");
          setIsUpload(false);
          setFiles(null);
          setTypeFile("");
          var mssg = {
            from: userData?._id,
            to: Data[0]?._id,
            messages: response.data.messages,
            room: GetGroupRoom,
          };
          Socket.emit("group-message", mssg);
          const data = {
            to: IsOnline,
            from: userData?._id,
            id: userData?._id,
            message: message,
            fileName: response.data.messages[0].fileName === "" ? "" : "image",
            room: GetGroupRoom,
          };
          Socket.emit("group-message-new", data);
          Socket.emit("chat-seen", data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Enter Msg");
    }
  };
  const handlleApi = () => {
    if (Data.length > 1) {
      AddGroup();
    } else {
      Add();
    }
  };
  const onChangeInput = (Text) => {
      setMessage(Text);
  };
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      if (room_id) {
        const data = {
          room: room_id,
          id: userData?._id,
        };
        Socket.emit("leave-room", data);
        console.log(data, "i am data leave");
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="chat-Main">
        <div className="text-start rounded-3 overflow-hidden chatbox bg-white">
          {/* TopBar */}
          <div
            className="py-2 d-flex justify-content-between align-items-center text-white"
            style={{ backgroundColor: "#6148A1" }}
          >
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOnline([]);
                if (Data.length === 1) {
                  const data = {
                    room: room_id,
                    id: userData?._id,
                  };

                  const datatype = {
                    from: userData?._id,
                    to: Data[0]?._id,
                    room: room_id,
                    statustyping: false,
                  };
                  Socket.emit("typing", datatype);
                  Socket.emit("leave-room", data);
                  // Socket.emit("close-chat", data);
                  navigate(`/Profiless/${Data[0]?._id}`, {
                    state: {
                      item: Data[0]?._id,
                    },
                  });
                  setChatBox();
                } else {
                  const data = {
                    room: GetGroupRoom,
                    id: userData?._id,
                  };
                  Socket.emit("leave-room", data);
                  // Socket.emit("close-chat", data);
                }
              }}
            >
              {Is_Online ? (
                <img
                  src={"/Images/contact-online.png"}
                  height={10}
                  className="mx-3 mt-n1"
                  alt="ibouge"
                />
              ) : (
                <img
                  src={"/Images/contact-idle.png"}
                  height={10}
                  className="mx-3 mt-n1"
                  alt="ibouge"
                />
              )}
              <div
                className="lh-1"
                onClick={() => {
                  setShowChatUsers(!showChatUsers);
                }}
              >
                <h6 className={Data.length > 1 ? "my-n1" : "mt-2"}>
                  {Data === undefined
                    ? user?.fname
                    : Data.length > 1
                    ? "Group Chat"
                    : Data[0]?.item?.fname.concat(" ", Data[0]?.item?.lname)}
                </h6>
                {Data.length > 1 ? (
                  <span className="mt-n2 lh-sm" style={{ fontSize: 11 }}>
                    {Data[0]?.item?.fname}
                    {Data[0]?.item?.lname !== "" ? (
                      <>&nbsp;{Data[0]?.item?.lname}</>
                    ) : null}
                    ,&nbsp;
                    {Data[1]?.item?.fname}
                    {Data[1]?.item?.lname !== "" ? (
                      <>&nbsp;{Data[1]?.item?.lname}</>
                    ) : null}
                    &nbsp;and&nbsp;
                    {/* {Data.length > 3 ?  <>{Data.length - 2}+</> : <>{Data[2]?.item?.fname}&nbsp;{Data[2]?.item?.lname}</>} */}
                    {Data.length > 2 ? <>{Data.length - 2} others</> : null}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <FiX
              className="fs-4 text-white me-3"
              role="button"
              onClick={() => {
                setIsOnline([]);
                if (Data.length === 1) {
                  const data = {
                    room: room_id,
                    id: userData?._id,
                  };

                  const datatype = {
                    from: userData?._id,
                    to: Data[0]?._id,
                    room: room_id,
                    statustyping: false,
                  };
                  Socket.emit("typing", datatype);
                  Socket.emit("leave-room", data);
                  // Socket.emit("close-chat", data);
                } else {
                  const data = {
                    room: GetGroupRoom,
                    id: userData?._id,
                  };
                  Socket.emit("leave-room", data);
                  // Socket.emit("close-chat", data);
                }
                setChatBox();
              }}
            />
          </div>
          {showChatUsers && (
            <div
              className="d-flex flex-row mt-1 mx-2 overflow-scroll w-100"
              style={{ width: 300 }}
            >
              {Data.length > 0
                ? Data.map((individual) => {
                    return (
                      <div className="">
                        <div className="m-2 lh-1 text-center">
                          <img
                            src={
                              individual?.item?.profile_pic
                                ? individual?.item?.profile_pic
                                : "/img/upload-photo.png"
                            }
                            alt=""
                            className="rounded-5"
                            style={{ width: 34, height: 34 }}
                          />
                          <span className="text-black text-center lh-1">
                            {individual?.item?.fname
                              ? individual?.item?.fname
                              : null}{" "}
                            {individual?.item?.lname
                              ? individual?.item?.lname
                              : null}
                          </span>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
          {/* Messages List */}
          <div
            className="d-flex flex-column-reverse pt-2 "
            style={{
              backgroundColor: "#fff",
              height: "300px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div className="chats">
              {loaderVideo === true ? (
                <div style={{ margin: "10% 40%" }}>
                  <Loader />
                </div>
              ) : Loading === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "5%",
                  }}
                >
                  <div className="loading2"></div>
                </div>
              ) : ShowMessage.length <= 0 ? (
                ""
              ) : (
                ShowMessage.map((data, index) => {
                  const string = data?.message.match(
                    /[a-zA-Z0-9.,/'";:!@$%&*()_+=-±~?#€[{}^><|/]+/
                  );
                  var ranges = data.message.match([
                    "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
                  ]);
                  return (
                    <div key={index}>
                      {data.from === userData?._id ? (
                        <>
                          {isReadByOpen.state === 1 ? (
                            <div
                              className="rounded-3 px-sm-2 px-1 bg-light mx-1 text-dark"
                              style={{
                                position: "absolute",
                                bottom: "60px",
                                boxShadow:
                                  "0px 0px 30px 1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.19)",
                                zIndex: "105",
                                width: "98%",
                              }}
                            >
                              <div className="d-flex justify-content-between bg-white align-content-center border-bottom border-secondary border-opacity-25 py-3 px-2">
                                <span className="fw-bold">
                                  Message seen by following user
                                </span>
                                <FiX
                                  style={{ cursor: "pointer" }}
                                  color="black"
                                  size={18}
                                  onClick={() => {
                                    setIsReadByOpen({ state: 0 });
                                  }}
                                />
                              </div>
                              <div
                                className="d-flex flex-column gap-3 px-1 px-sm-2 bg-white my-2"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "scroll",
                                }}
                              >
                                {ShowMessage[isReadByOpen.index].seen_by.map(
                                  (user) => {
                                    return (
                                      <div className="gap-2 d-flex align-items-center">
                                        <img
                                          src={
                                            user?.profile
                                              ? user.profile
                                              : "/img/upload-photo.png"
                                          }
                                          className="rounded-5"
                                          alt=""
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                            marginLeft: "2px",
                                            cursor: "pointer",
                                          }}
                                          // title={user?.name? user.name : "Old User"}
                                          onClick={() =>
                                            navigate(
                                              `/Profiless/${user.user_id}`,
                                              {
                                                state: {
                                                  item: user.user_id,
                                                },
                                              }
                                            )
                                          }
                                        />
                                        <h6 className="ms-3">{user.name}</h6>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                          <div className="sent p-3">
                            {/* ^ Outgoing Message Bubble */}
                            <div className="order-2">
                              <img
                                src={
                                  userData?.profile_pic
                                    ? userData?.profile_pic
                                    : "/img/upload-photo.png"
                                }
                                height={35}
                                className="rounded-circle image-user imagesss"
                                alt="ibouge"
                              />
                            </div>
                            <div
                              className="msgBubble p-2 rounded-3 order-1"
                              style={{
                                backgroundColor: "#6148a1",
                                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.35)",
                              }}
                            >
                              <span
                                className="text-white"
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  fontSize: "10px",
                                }}
                              >
                                {/* {moment(data.time).format("LT")} */}
                                {/* {moment(data.time).format("MMM DD h:mm")} */}
                                {moment(data.time).format(
                                  "MMMM Do, YYYY  h:mm A"
                                )}
                              </span>
                              <br />
                              {data.fileName ? (
                                data.message_type === "video" ? (
                                  <video
                                    width="98%"
                                    height="350px"
                                    style={{ borderRadius: "6px" }}
                                    controls
                                  >
                                    <source src={data.fileName} />
                                  </video>
                                ) : (
                                  <ModalImage
                                    hideDownload
                                    className="pro-img border rounded-3 w-100 mt-1"
                                    small={data.fileName}
                                    large={data.fileName}
                                    alt={""}
                                  />
                                )
                              ) : null}
                              {data.message.match(/http[s]?:\/\/\S+/) ? (
                                <>
                                  <Linkify>{data.message}</Linkify>
                                  <LinkPreview
                                    url={data.message}
                                    color={"white"}
                                  />
                                </>
                              ) : (
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: string
                                      ? "13px"
                                      : ranges?.input.length < 7
                                      ? "50px"
                                      : "13px",
                                    color: "white",
                                    outline: " 0px solid transparent",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {data.message}
                                </span>
                              )}
                              <div>
                                {data.read_at == null ? (
                                  Is_Online === true ? (
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "white",
                                      }}
                                    >
                                      Delivered
                                    </span>
                                  ) : null
                                ) : (
                                  <span
                                    style={{ fontSize: "10px", color: "white" }}
                                  >
                                    seen{" "}
                                    {moment(data.read_at).format("L") ===
                                    moment(new Date()).format("L")
                                      ? moment(data.read_at).format("LT")
                                      : moment(data.read_at).format(
                                          "ddd, MMMM Do,  h:mma"
                                        )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-end px-3"
                            style={{
                              marginTop: "-10px",
                              marginLeft: "15px",
                            }}
                          >
                            {data?.seen_by == undefined ? null : data?.seen_by
                                ?.length <= 5 ? (
                              data?.seen_by
                                ?.filter(
                                  (user) => user.user_id !== userData._id
                                )
                                .map((user, index) => {
                                  return (
                                    <div className="d-flex flex-column justify-content-between gap-2">
                                      {readByNameOpen.length > 0 ? (
                                        <div
                                          className="text-dark mb-3 px-3 position-absolute"
                                          style={{ bottom: "65px" }}
                                          key={index}
                                        >
                                          <span>{readByNameOpen}</span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <img
                                        src={
                                          user?.profile
                                            ? user.profile
                                            : "/img/upload-photo.png"
                                        }
                                        alt=""
                                        className="rounded-5 img-fluid"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          marginLeft: "2px",
                                          cursor: "pointer",
                                        }}
                                        // title={user.lname? user.fname + " " + user.lname: user.fname}
                                        onClick={() =>
                                          navigate(
                                            `/Profiless/${user.user_id}`,
                                            {
                                              state: {
                                                item: user.user_id,
                                              },
                                            }
                                          )
                                        }
                                        onMouseEnter={() => {
                                          setReadByNameOpen({
                                            name: user.lname
                                              ? user.fname + " " + user.lname
                                              : user.fname,
                                          });
                                        }}
                                        onMouseLeave={() => {
                                          setReadByNameOpen({ name: "" });
                                        }}
                                      />
                                    </div>
                                  );
                                })
                            ) : (
                              <div className="d-flex">
                                {data?.seen_by
                                  ?.slice(0, 4)
                                  .filter(
                                    (user) => user.user_id !== userData._id
                                  )
                                  .map((user, index) => {
                                    return (
                                      <div className="d-flex flex-column justify-content-between gap-2">
                                        {readByNameOpen.length > 0 ? (
                                          <div
                                            className="text-dark mb-3 px-3 position-absolute"
                                            style={{ bottom: "65px" }}
                                            key={index}
                                          >
                                            <span>{readByNameOpen}</span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <img
                                          src={
                                            user?.profile
                                              ? user.profile
                                              : "/img/upload-photo.png"
                                          }
                                          alt=""
                                          className="rounded-5 img-fluid"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginLeft: "2px",
                                            cursor: "pointer",
                                          }}
                                          // title={user.lname? user.fname + " " + user.lname: user.fname}
                                          onClick={() =>
                                            navigate(
                                              `/Profiless/${user.user_id}`,
                                              {
                                                state: {
                                                  item: user.user_id,
                                                },
                                              }
                                            )
                                          }
                                          onMouseEnter={() => {
                                            setReadByNameOpen({
                                              name: user.lname
                                                ? user.fname + " " + user.lname
                                                : user.fname,
                                            });
                                          }}
                                          onMouseLeave={() => {
                                            setReadByNameOpen({ name: "" });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                <p
                                  className="px-1 text-decoration-underline text-dark"
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    fontSize: "11px",
                                  }}
                                  role="button"
                                  onClick={() => {
                                    setIsReadByOpen({ state: 1, index: index });
                                  }}
                                >
                                  + {data?.seen_by.length - 4} others
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="recieved p-3">
                            {/* ^ Incoming Message Bubble */}
                            <div className="order-1">
                              <img
                                src={
                                  data?.profile
                                    ? data?.profile
                                    : "/img/upload-photo.png"
                                }
                                height={35}
                                className="rounded-circle image-user"
                                alt="ibouge"
                              />
                            </div>
                            <div
                              className="msgBubble p-2 rounded-3 ps-4 order-2"
                              style={{
                                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.35)",
                              }}
                            >
                              <span
                                className="text-dark ps-1"
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  fontSize: "10px",
                                }}
                              >
                                {/* {moment(data.time).format("MMM DD h:mm")} */}
                                {moment(data.time).format(
                                  "MMMM Do, YYYY  h:mm A"
                                )}
                              </span>
                              <br />

                              {data.fileName ? (
                                data.message_type === "video" ? (
                                  <video
                                    width="98%"
                                    height="350px"
                                    style={{ borderRadius: "6px" }}
                                    controls
                                  >
                                    <source src={data.fileName} />
                                  </video>
                                ) : (
                                  <ModalImage
                                    hideDownload
                                    className="pro-img border rounded-3 w-100 mt-0"
                                    small={data.fileName}
                                    large={data.fileName}
                                    alt={""}
                                  />
                                )
                              ) : null}
                              {data.message.match(/http[s]?:\/\/\S+/) ? (
                                <>
                                  <Linkify>{data.message}</Linkify>
                                  <LinkPreview url={data.message} />
                                </>
                              ) : (
                                <span
                                  className="ps-1"
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: string
                                      ? "13px"
                                      : ranges?.input.length < 7
                                      ? "50px"
                                      : "13",
                                    color: "black",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {data.message}
                                </span>
                              )}
                              {/* <p>{data.read_at}</p> */}
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-end px-3"
                            style={{
                              marginTop: "-10px",
                              marginLeft: "15px",
                            }}
                          >
                            {data?.seen_by == undefined ? null : data?.seen_by
                                ?.length <= 5 ? (
                              data?.seen_by
                                ?.filter(
                                  (user) => user.user_id !== userData._id
                                )
                                .map((user, index) => {
                                  return (
                                    <div className="d-flex flex-column justify-content-between gap-2">
                                      {readByNameOpen.length > 0 ? (
                                        <div
                                          className="text-dark mb-3 px-3 position-absolute"
                                          style={{ bottom: "65px" }}
                                          key={index}
                                        >
                                          <span>{readByNameOpen}</span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <img
                                        src={
                                          user?.profile
                                            ? user.profile
                                            : "/img/upload-photo.png"
                                        }
                                        alt=""
                                        className="rounded-5 img-fluid"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          marginLeft: "2px",
                                          cursor: "pointer",
                                        }}
                                        // title={user.lname? user.fname + " " + user.lname: user.fname}
                                        onClick={() =>
                                          navigate(
                                            `/Profiless/${user.user_id}`,
                                            {
                                              state: {
                                                item: user.user_id,
                                              },
                                            }
                                          )
                                        }
                                        onMouseEnter={() => {
                                          setReadByNameOpen({
                                            name: user.lname
                                              ? user.fname + " " + user.lname
                                              : user.fname,
                                          });
                                        }}
                                        onMouseLeave={() => {
                                          setReadByNameOpen({ name: "" });
                                        }}
                                      />
                                    </div>
                                  );
                                })
                            ) : (
                              <div className="d-flex">
                                {data?.seen_by
                                  ?.slice(0, 4)
                                  .filter(
                                    (user) => user.user_id !== userData._id
                                  )
                                  .map((user, index) => {
                                    return (
                                      <div className="d-flex flex-column justify-content-between gap-2">
                                        {readByNameOpen.length > 0 ? (
                                          <div
                                            className="text-dark mb-3 px-3 position-absolute"
                                            style={{ bottom: "65px" }}
                                            key={index}
                                          >
                                            <span>{readByNameOpen}</span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <img
                                          src={
                                            user?.profile
                                              ? user.profile
                                              : "/img/upload-photo.png"
                                          }
                                          alt=""
                                          className="rounded-5 img-fluid"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginLeft: "2px",
                                            cursor: "pointer",
                                          }}
                                          // title={user.lname? user.fname + " " + user.lname: user.fname}
                                          onClick={() =>
                                            navigate(
                                              `/Profiless/${user.user_id}`,
                                              {
                                                state: {
                                                  item: user.user_id,
                                                },
                                              }
                                            )
                                          }
                                          onMouseEnter={() => {
                                            setReadByNameOpen({
                                              name: user.lname
                                                ? user.fname + " " + user.lname
                                                : user.fname,
                                            });
                                          }}
                                          onMouseLeave={() => {
                                            setReadByNameOpen({ name: "" });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                <p
                                  className="px-1 text-decoration-underline text-dark"
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    fontSize: "11px",
                                  }}
                                  role="button"
                                  onClick={() => {
                                    setIsReadByOpen({ state: 1, index: index });
                                  }}
                                >
                                  + {data?.seen_by.length - 4} others
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  );
                }).reverse()
              )}
              <div ref={downRef}></div>
              {TypingIcon && Data.length === 1 ? (
                <div className="chat-bubble">
                  <div className="typing">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              ) : (
                <div className="chat-bubble" style={{ margin: "-4.1px" }}>
                  <div className="typing"></div>
                </div>
              )}
              {typingNames.length>0 && Data.length > 1 ? 
                typingNames.length==1?
                  <div className="chat-bubble d-block ps-3 pb-2" style={{fontSize: 13}}>
                    <i className="typing text-dark">{typingNames[0].name} is typing ...</i>
                  </div>:
                  typingNames.length==2?
                  <div className="chat-bubble d-block ps-3 pb-2" style={{fontSize: 13}}>
                    <i className="typing text-dark">{typingNames[0].name} and {typingNames[1].name} are typing ...</i>
                  </div>:
                  <div className="chat-bubble d-block ps-3 pb-2" style={{fontSize: 13}}>
                    <i className="typing text-dark">{typingNames[0].name} and {typingNames.length -1} others typing ...</i>
                  </div> :
                <div className="chat-bubble" style={{ margin: "-4.1px" }}>
                  <div className="typing"></div>
                </div>
              }
              {IsUpload === true ? (
                <div>
                  {TypeFile === "video" ? (
                    <div className="position-relative" style={{ width: "93%" }}>
                      <video
                        ref={videoRef}
                        style={{ marginLeft: "13px", borderRadius: "5px" }}
                        width="100%"
                        height="200px"
                        controls
                      >
                        <source src={image} />
                      </video>
                      <FiX
                        className=""
                        color="black"
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-18px",
                          zIndex: "100",
                          backgroundColor: "#ECECEC",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                        }}
                        size={22}
                        onClick={() => {
                          setIsUpload(false);
                          setVideoSrc(null);
                          setFiles(null);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="position-relative" style={{ width: "95%" }}>
                      <ModalImage
                        hideDownload
                        className="chatImage"
                        small={image}
                        large={image}
                        alt={"Global Newsfeed"}
                      />
                      <FiX
                        className="position-absolute"
                        color="black"
                        style={{
                          right: "-10px",
                          top: "-3px",
                          zIndex: "1",
                          backgroundColor: "#ECECEC",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                        }}
                        size={22}
                        onClick={() => {
                          setIsUpload(false);
                          setImage("");
                          setFiles(null);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <BsPaperclip
            style={{
              zIndex: "10",
              backgroundColor: "#6148A1",
              marginTop: 7,
              // bottom: "8px",
              left: "17px",
              padding: "5px",
              cursor: "pointer",
            }}
            className="position-absolute rounded-1"
            color="white"
            size={27}
            onClick={onButtonClick}
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <div id="text" className="d-flex align-items-center pb-2">
            <div style={{ minWidth: "85%" }} className="d-flex">
              <InputEmoji
                className="react-emoji-picker"
                placeholder=""
                borderRadius={5}
                theme={"auto"}
                onEnter={() => handlleApi()}
                cleanOnEnter
                onChange={(Text) => onChangeInput(Text)}
                value={message}
                // onEnter={handleChange}
              />
            </div>
            <AiOutlineSend
              size={40}
              color="white"
              style={{ backgroundColor: "#6148A1" }}
              className="p-2 rounded-1 me-2"
              onClick={() => handlleApi()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
