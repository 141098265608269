import React, { useState } from "react";
import "../../../assets/styles/Footer/contactUs.css";
import "../../../assets/styles/Footer/footerAbout.css";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import FooterNav from "./FooterNav";
import { Card } from "reactstrap";
import { MdEmail, MdInfo } from "react-icons/md";
import { BsCheckCircle, BsTagFill } from "react-icons/bs";
import { RiPencilFill } from "react-icons/ri";
import { Server2 } from "../../../redux/actions/auth/auth.actions";
import axios from "axios";
import { Helmet } from "react-helmet";
import FootNav from "../../User_Profile/Footer/Footer-nav/FootNav";

const Contact = () => {
  const [Mail , setMail] =  useState('')
  const [Name , setName] =  useState('')
  const [Subject , setSubject] =  useState('')
  const [Message , setMessage] =  useState('')
  const [error , setError] =  useState('')
  const [popup, setpopup] = useState(false)
  const Submit = () =>{
    if (!Name.trim()) {
      setError("eventname");
    }else if (!Mail.trim()) {
      setError("Mail");
    }else if (!Subject.trim()) {
      setError("Subject");
    }else if (!Message.trim()) {
      setError("Message");
    }else{
    const data = {
      from : Name,
      email: Mail,
      subject : Subject,
      text:Message
    }
    const config = {
      method:"POST",
      url:Server2 +"/chats/contact",
      data: data,
    }
    axios(config)
    .then((response) => {
      setpopup(true)
      setTimeout(() => {
        setpopup(false)
      }, 3000);
      setMail('')
      setMessage('')
      setName('')
      setSubject('')
    })
    .catch((err) =>{
      console.log(err , "i am error")
    })
    }
  }
  return (
    <div style={{background:"white"}}>
          <Helmet>
        <meta property="og:url" content="contact" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="iBouge, a geo-network. We use maps to connect the global community" />
        <meta property="og:image" content=" images/IMG_6188.jpeg" />
      </Helmet>
      <FooterNav from={"Contact"}/>
      <noscript>
        <iframe
          title="GoogleManager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-NWKST7R"
          height="0"
          width="0"
          className="d-none invisible"
        />
      </noscript>

      <div className="MainContact">
        <div className="Contactheading">
          <p>Contact Us</p>
        </div>
        {/* maps start */}
        <div id="map-container-section" className="map-container-section px-2">
          <iframe
            title="Google Maps"
            src="https://maps.google.com/maps?q=Seattle&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            className=""
          />
        </div>
        
        <div className="contact-card-main">
          <Card className="Contact-card-customize">
            <div className="border_top"></div>
            <div className="mt-3">
              <span className="Contact-LoginHeading">Write to us</span>
              <p className="contact-p">
                We would love to hear from you! Please leave us a message and
                we'll be in touch shortly.
              </p>
            </div>
            <div className="position-relative">
              <label className="contact-Labelsignup">Your Name</label>
              <MdInfo className="contactMdinfo" color="black" size={20} />
              <input
                className="form-control Contact-input-btn "
                type="text"
                placeholder="Your Name"
                name="Your Name"
                onChange={(e) => setName(e.target.value)}
                style={{
                        borderColor:
                          Name.length > 0
                            ? "#ECECEC"
                            : error === "eventname"
                            ? "red"
                            : "#ECECEC",
                      }}
                      value={Name}
              />
              
            </div>
            <div className="position-relative">
              <label className="contact-Labelsignup">Email Address</label>
              <MdEmail className="contactMdinfo" color="black" size={20} />
              <input
                className="form-control Contact-input-btn"
                name="email"
                type="email"
                placeholder="Email Address"
                onChange={(e) => setMail(e.target.value)}
                style={{
                        borderColor:
                          Mail.length > 0
                            ? "#ECECEC"
                            : error === "Mail"
                            ? "red"
                            : "#ECECEC",
                      }}
                      value={Mail}
              />
            </div>
            <div className="position-relative">
              <label className="contact-Labelsignup">Subject</label>
              <BsTagFill className="contactMdinfo" color="black" size={20} />
              <input
                className="form-control Contact-input-btn "
                type="text"
                placeholder="Query"
                name="Subject"
                onChange={(e) => setSubject(e.target.value)}
                style={{
                        borderColor:
                          Subject.length > 0
                            ? "#ECECEC"
                            : error === "Subject"
                            ? "red"
                            : "#ECECEC",
                      }}
                      value={Subject}
              />
            </div>
            <div className="position-relative">
              <label className="contact-Labelsignup">Send Message</label>
              <RiPencilFill className="contactMdinfo" color="black" size={20} />
              <textarea
                className="form-control Contact-input-btn "
                type="text"
                placeholder="Message"
                name="Message"
                rows={2}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                        borderColor:
                          Message.length > 0
                            ? "#ECECEC"
                            : error === "Message"
                            ? "red"
                            : "#ECECEC",
                      }}
                      value={Message}
              />
            </div>
            {popup === true ?
          <div style={{ backgroundColor: "#d2f8d2", margin: "10px 20px 0px 20px", padding: "9px 9%", borderRadius: "8px" }}>
            <BsCheckCircle style={{ marginRight: "15px" }} color="#0b4a0b" size={18} />
            <span style={{ fontSize: "13px" }}>Your message was sent successfully</span>
          </div> : null
        }
            <button onClick={Submit} className="contact-btn-submit border-0 text-white">
              Submit
            </button>
          </Card>
          <Card className="Contact-card-customize location-Main">
            <div className="border_top"></div>
            <div className="contact-Main-Location">
            <div className="mt-3 Contact-Location">
              <FaMapMarkerAlt className="mb-2" size={20} color="black" />
              <h6>Global Headquarters:</h6>
              <h6>Redmond, WA 98052</h6>
              <h6>United States</h6>
            </div>
            <div className="mt-3 Contact-Location">
              <FaPhoneAlt className="mb-2" size={20} color="black" />
              {/* <h6>+ 1 415 484 6056</h6> */}
              <h6>Mon - Fri: 9:00-17:00 PST</h6>
            </div>
            <div className="mt-3 Contact-Location">
              <MdEmail className="mb-2" color="black" size={20} />
              <h6>info@ibouge.com</h6>
              <h6>advertise@ibouge.com</h6>
              <h6>work@ibouge.com</h6>
              <h6>support@ibouge.com</h6>
            </div>
            </div>
          </Card>
        </div>
      </div>
      <footer>
        <p>iBouge ® • Copyright © 2025 iBouge Inc. • All Rights Reserved.</p>
        <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
      </footer>
      <FootNav from="Contact"/>
      {/* <LastFooter /> */}
    </div>
  );
};

export default Contact;
