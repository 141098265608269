import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import LoginPage from "./loginPage";
import ForgotPassword from "../../../components/forgot";
import { useNavigate } from "react-router-dom";
import {
  googleLoginAuth,
  facebookLoginAuth,
} from "../../../redux/actions/auth/auth.actions";
import {
  signInWithfacebook,
  signInWithGoogle,
} from "../../../services/firebase";
import Footer from "../../../components/Footer";
import { useDispatch, useSelector } from "react-redux";

const CardComponetLogin = ({ loginFlag, forgotFlag, setForgotFalg }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const linkRes = useSelector((state) => state?.auth?.linkuser?.state);
  const userData = useSelector((state) => state?.auth?.user?.user);
  // getting Social user Responce Status & Message
  const [errorMsg, setError] = useState("");
  // useEffect to navigate social users to Steps or Home Page
  const check = (Res) => {
    setError("");
    if (Res?.user?.status === 200) {
      // Steps Navigate Personal
      if (Res?.user?.user?.type === false) {
        if (Res?.user?.user?.activation_status === 0) {
          navigate("/firstStep");
        } else if (Res?.user?.user?.activation_status === 1) {
          navigate("/secondStep");
        } else if (Res?.user?.user?.activation_status === 2) {
          navigate("/thirdStep");
        } else {
          navigate("/home", {
            state: {
              coord: [0, 0],
            },
          });
        }
      }
    } else if (Res?.user?.status === 201) {
      if (Res?.user?.status) {
        setError("");
        setError(Res?.user?.message);
      }
    } else if (Res?.user?.status === 403) {
      if (Res?.user?.status) {
        setError("");
        setError(Res?.user?.message);
      }
    }
  };

  // useEffect To set Social and normal users Email to Local Storage
  // Google ?Func On Click
  function googleLogin() {
    setError("");
    signInWithGoogle().then(({ user }) => {
      dispatch(googleLoginAuth({ email: user?._delegate?.email })).then(
        (res) => {
          if (res.err) {
            setError(res.err.message);
          } else {
            check(res);
          }
        }
      );
    });
  }

  // facebook func start
  const responseFacebook = () => {
    signInWithfacebook().then(({ user }) => {
      if (user) {
        dispatch(facebookLoginAuth({ email: user?._delegate?.email })).then(
          (res) => {
            setError("");
            if (res.err) {
              setError(res.err.message);
            } else {
              check(res);
            }
          }
        );
      }
    });
  };
  useEffect(() => {
    if (userData?.activation_status === 3) {
      navigate("/home", {
        state: {
          item: [
            userData.location.coordinates[0].coords.lng,
            userData.location.coordinates[0].coords.lat,
          ],
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="overflow-outo px-3">
      <div className="text-center img-logo mt-5">
        <img src="/img/Logo.png" alt="NoImage" className="FormLogo" />
        <span style={{color: "#9f47a0", position: "relative", top: "20px", fontSize: "1.2rem"}}>&#174;</span>
      </div>
      <Card className="card-customize">
        <div className="border_top"></div>
        {forgotFlag === false ? (
          <>
            <div className="mt-4 d-flex justify-content-center">
              <span className="LoginHeading">
                Welcome! Log in to your account
              </span>
            </div>
            {/* Setting responce Error */}
            {errorMsg !== null ? (
              <div
                // className={"error-text"}
                style={{
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "12px 28px 1px 28px",
                  textAlign: "center",
                }}
              >
                {errorMsg}
                <div className="text-center text-success">
                  {linkRes==="success"?"Successfully verified. Please log back in":""}
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className=" "
              onClick={() => {
                setError("");
              }}
            >
              {/* <div className="d-flex justify-content-around flex-row mt-3 ps-2 pe-2">
                <Button className="social-btn" onClick={responseFacebook}>
                  <img src="/img/facebook.png" height={24} alt="facebook" />
                  <span className="m-1">Facebook</span>
                </Button>

                <Button className="social-btn" onClick={googleLogin}>
                  <div>
                    <img src="/img/google.png" height={20} alt="Google" />
                  </div>
                  <span className="m-1">Google</span>
                </Button>
                <Button className="social-btn">
                  <div>
                    <img src="/img/Apple.png" height={30} alt="Apple" />
                  </div>
                  <span className="m-1">Apple</span>
                </Button>
              </div> */}
            </div>
            <Row>
               <Col lg="12" md="12" sm="12" xs="12">
                <div className="text-center mt-3 ">
                  {/* <p className="mute-text">Or</p> */}
                </div>
              </Col>
              <div style={{ marginTop: "-5px" }}>
                {loginFlag === false ? (
                  <LoginPage
                    setForgotFalg={setForgotFalg}
                    setError={() => setError("")}
                  />
                ) : (
                  ""
                )}
              </div>
            </Row>
          </>
        ) : (
          <ForgotPassword setForgotFalg={setForgotFalg} />
        )}
        {/* {forgotFlag === false ? (
          ""
        ) : (
          <div>
            <BiChevronLeft className="forgotAiLeft" size={27} />
            <button
              className="backToLogin"
              style={{ cursor: "grab" }}
              onClick={() => gobackFunc()}
            >
              Back
            </button>
          </div>
        )} */}
      </Card>
      <Footer />
      <div className="d-flex flex-column align-items-center justify-content-center text-center gap-sm-0 gap-3 mt-3 footerAuth_patents">
        <span className="fw-bold text-mute">iBouge ® • Copyright © 2025 iBouge Inc. • All Rights Reserved.</span>
        <span className="fw-bold text-mute">Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</span>
      </div>
    </div>
    
  );
};

export default CardComponetLogin;