import React, {useEffect, useRef, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../assets/styles/Footer/footerAbout.css'
import { Helmet } from "react-helmet";
import FooterNav from "./FooterNav";
import { useSelector } from 'react-redux';
import FootNav from '../../User_Profile/Footer/Footer-nav/FootNav';

const About = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  console.log(state, "state")
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [Loading, setLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isIntersecting, setIntersecting] = useState(false);
  const buttonRef1 = useRef(null);
  const buttonRef2 = useRef(null);

  useEffect(() => {
    setIsFlipped(true);
    const timer = setTimeout(() => {
      setIsFlipped(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
      const timer = setTimeout(() => {
        setIntersecting(false);
      }, 1000);
      return () => clearTimeout(timer)
    }
  );

    observer.observe(buttonRef1.current);
    return () => {
      observer.disconnect();
    };
  }, [buttonRef1]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
      const timer = setTimeout(() => {
        setIntersecting(false);
      }, 1000);
      return () => clearTimeout(timer)
    }
    );

    observer.observe(buttonRef2.current);
    return () => {
      observer.disconnect();
    };
  }, [buttonRef2]);

  useEffect(() =>{
    // if (userData && userData?.type === false) {
    //   if (userData?.activation_status === 0) {
    //     navigate("/firstStep");
    //   } else if ( userData?.activation_status === 1) {
    //     navigate("/secondStep");
    //   } else if ( userData?.activation_status === 2) {
    //     navigate("/thirdStep");
    //   } else {
    //     navigate('/home', { 
    //       state: { 
    //         item: [
    //           userData.location.coordinates[0].coords.lng,
    //           userData.location.coordinates[0].coords.lat,
    //         ],
    //       },
    //     });
    //   }
    // }
    // setTimeout(() => {
    //   setLoading(false)
    // }, 3000)
    if(userData && state !== "FooterMain"){
      (!userData.type && userData?.activation_status === 3) || (userData?.type && userData?.subscription) ?
          navigate('/home', { 
            state: { 
              item: [
                userData.location.coordinates[0].coords.lng,
                userData.location.coordinates[0].coords.lat,
              ],
            },
          })
      :
      navigate('/login')
    }
  },[])
  return (
    <>
      <Helmet>
        <meta property="og:url" content="/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="iBouge, a geo-network. We use maps to connect the global community" />
      </Helmet>
    <div id="wrapper" className='bg-light'>
    <div className="pre-content"></div>
    <div className="about-content w-100">
      <FooterNav statusL = {userData? "LoggedIn" : ""} from = {"About"}/>
      {/* <div className="d-flex justify-content-end">
        <header className='aboutheader'>
          <img src="/Images/logo.png" alt="Ibouge Logo" style={{cursor:"pointer"}} onClick={() => navigate('/')}  />
          <span style={{color: "#9f47a0", position: "relative", top: "-2px", fontSize: "1.1rem"}}>&#174;</span>
        </header>
        <button
          className="signup_button text-white ms-auto border-0 shadow-none"
          style={{position:"relative", top: 40}}
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign Up
        </button>
      </div> */}
      <main className='aboutmain mx-auto'>
        <section className='aboutsection' id="what">
          <div className="about-what-left">
            <div className="about-what-left-bg" style={{zIndex:-10}}></div>
            <div className='h-100 px-4 pt-4 pb-3'>
            {Loading === true ?
              <div style={{ display: "flex", justifyContent: "center", alignItems:"center", height: "100px", margin: "5%" }}>
                <div className="loading">
                </div>
              </div>
              :
                // <iframe
                //   width="100%"
                //   height="100%"
                //   margin="10px"
                //   do-not-allow="autoplay"
                //   src={"video/ibouge.mp4"}
                //   allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                //   allowFullScreen
                //   playsInline
                //   title="Embedded youtube"
                // />
                <video width="100%" height="100%" controls>
                  <source src="video/ibouge.mp4" type="video/mp4"/>
                </video>
            }
            </div>
          </div>
          <div className="what-right topper">
            <div className='d-flex mt-5 flex-row pt-2 px-0 px-sm-2' >
              <div className='text-start'>
                <span>Introducing iBouge</span>
                <h1 className='about-h1' >What is iBouge<small style={{fontSize:"14px", marginRight:"2px", position:"relative", top: "-10px"}} >&#174;</small>?</h1>
              </div>
              <button
                className={`signup_button_about text-white mt-3 ms-auto border-0 shadow-none ${isFlipped ? "flip" : ""}`}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </button>
            </div>
            <div className='d-flex flex-column align-items-md-start align-items-center px-2 pe-md-5 ps-md-0'>  
              <p>iBouge is a mapping platform that places people, products and information on a global map interface to become more accessible and easier to find by the global internet population, allowing them to interact in new ways.</p>
              <p>Currently we offer 4 products on our map interface: </p>
              <ul>
                <li><strong>1</strong> Meeting people nearby</li>
                <li><strong>2</strong> Global business pages</li>
                <li><strong>3</strong> Map based chat conversations about points of interest</li>
                <li><strong>4</strong> Local events that are pinned on our map and broadcast to anyone who is following that area.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className='aboutsection' id="diff">
          <div className="diff-content topper">
            <h1 className='about-h1'><span>What differentiates <br/>
            the platform </span>from <br/>
            other social media <br/>
            companies?</h1>

            <p>Our patented and unique global map interface <br/>
            categorizes content in a new way and allows <br/>
            people to be found and content to be seen<br/>
            on a global scale. iBouge &#174; users can<br/>
            connect with anyone in the world and <br/>
            businesses can reach any audience<br/>
            around the globe no matter where<br/>
            they’re located. </p>

            <img src="/Images/world.png" alt="" />
          </div>
        </section>

        <section className='aboutsection' id="who">
          <div className="who-image">
            <img src="/Images/cards.png" alt="" />
          </div>
          <div className="who-content">
            <h1 className='about-h1'>Who should sign up for<br />
            an account and why?<br/>
            <span>We offer two types<br/>
            of accounts.</span></h1>

            <img src="/Images/cards.png" alt="" />

            <p style={{color:"black"}}>Individual personal accounts which are free and paid business accounts.</p>
          </div>
          <div className="who-content">
            <ul>
              <li>Our global business pages are tailored to small businesses looking for broad marketing exposure and instant international visibility</li>
              <li>Our business pages list your company info just like Google Maps with map findability but also with the added benefit of group chat, direct messaging, social media features and your own personal wall for company branding and updates.</li>
              <li style={{fontSize: 18, fontWeight: 1000, lineHeight: 1}}>Our business pages are only $25 dollars a year with global visibility.</li>
            </ul>
          </div>
        </section>

        <section className='aboutsection' id="sign-up">
          <div className="sign-up-content">
            <div className="suc-title">
              <h1 className='about-h1 pe-4'>Ok, I’m sold, <br/>
              <span>how do I sign up?</span></h1>
              <p>You can sign up as a free user or paid business user on our registration sign up page and select which type of account you’d like in our account Type dropdown</p>
              <div className='suc-button'>
                <button
                  className={`signup_button_about text-white mt-3 border-0 shadow-none ${isIntersecting ? "signup-flip" : ""}`}
                  ref={buttonRef1}
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="sup-process">
              <div className="step step1">
                <img src="/Images/app1.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 1</span>
                  <p>Fill out the company details, add the company’s website and a company profile photo.</p>
                </div>
              </div>
              <div className="step step2">
                <img src="/Images/app2.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 2</span>
                  <p>Enable your browser to share its location with iBouge &#174; so we can tag your business where it’s located.</p>
                </div>
              </div>
              <div className="step step3">
                <img src="/Images/app3.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 3</span>
                  <p>Select your top 3 favorite cities to follow for updates to your newsfeed. You can change these cities at any time to explore new places.</p>
                </div>
              </div>
              <div className="step step4">
                <img src="/Images/app4.png" alt="" />
                <div className="desc">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Step 4</span>
                  <p>Fill out your credit card details to begin your new membership using our secure transaction partner Stripe.</p>
                </div>
              </div>
              <div className="step step5">
                <img src="/Images/app5.png" alt="" />
                <div className="desc done">
                  <span className='px-1 px-sm-3 py-0 py-sm-1 py-md-2'>Done</span>
                  <p>Your business page will automatically activate and then log into the platform.</p>
                </div>
              </div>

              <div className="form">
                <h2 className='mb-n1'>Sign Up Your Business Now for only $25 A Year</h2>
                <p className='pt-n1'>and start enjoying the benefits of using our platform</p>
                <button
                  className={`${isIntersecting ? "signup-flip" : ""}`}
                  ref={buttonRef2}
                  onClick={() => navigate('/signup',{
                    state:"business"
                  }
                )}>Sign Up</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
  <footer>
    <p>iBouge ® • Copyright © 2025 iBouge Inc. • All Rights Reserved.</p>
    <p>Patent numbers US D921,652; US D921,653; US D921,032; US D921,006; US D921,005; US D921,004.</p>
  </footer>
  <FootNav from={"About"} />
  </>
  )
}

export default About